import { api } from "@/lib/api-client";
import { PutPricingTier } from "@/server-types";
import { Button, NumberInput, Switch } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { LucideArrowLeft } from "lucide-react";
import React, { useEffect, useState } from "react";
import { RiArrowLeftSLine } from "react-icons/ri";
import { Link, useParams } from "react-router";

type PricingTierEditForm = {
  tierPrice: number;
  features: string[];
  maxLocationCount: number;
  maxUserCount: number;
  maxResourceCount: number;
  smsLimit: number;
};

const PricingTierEdit = () => {
  const { pricingTierId } = useParams();

  const { data, isLoading, refetch } =
    api.admin.useGetPricingTier(pricingTierId);

  const { mutateAsync: putPricingTier } = api.admin.usePutPricingTier();

  const form = useForm<PricingTierEditForm>({
    initialValues: {
      features: [],
      tierPrice: 0,
      maxLocationCount: 0,
      maxUserCount: 0,
      maxResourceCount: 0,
      smsLimit: 0,
    },
  });

  useEffect(() => {
    if (data) {
      form.initialize({
        features: data.pricingTier.pricing_tier_feature.map(
          (feature) => feature.featureId,
        ),
        tierPrice: data.pricingTier.tierPriceCents / 100,
        maxLocationCount: data.pricingTier.maxLocationCount,
        maxUserCount: data.pricingTier.maxUserCount,
        maxResourceCount: data.pricingTier.maxResourceCount,
        smsLimit: data.pricingTier.smsLimit,
      });
    }
  }, [data, form]);

  if (isLoading) return <div>Loading...</div>;

  if (!data) return <div>No data</div>;

  const onSubmit = async (values: PricingTierEditForm) => {
    try {
      await putPricingTier({
        body: {
          tierPriceCents: values.tierPrice * 100,
          features: values.features,
          maxLocationCount: values.maxLocationCount,
          maxUserCount: values.maxUserCount,
          maxResourceCount: values.maxResourceCount,
          smsLimit: values.smsLimit,
        },
        pricingTierId: pricingTierId!,
      });

      notifications.show({
        title: "Uspešno ste posodobili paket",
        message: "Paket je bil uspešno posodobljen",
      });
    } catch (error) {
      notifications.show({
        title: "Napaka pri posodabljanju paketa",
        message: "Pri posodabljanju paketa je prišlo do napake",
        color: "red",
      });
      console.error(error);
    }
  };

  console.log("form ", form.values);

  return (
    <div className="flex flex-col gap-2 py-2">
      <div className="flex items-center gap-2">
        <Link to={`/dashboard/admin/pricing-tiers`}>
          <LucideArrowLeft className="w-fit" />
        </Link>
        {data.pricingTier.tierName}
      </div>
      <form onSubmit={form.onSubmit(onSubmit)} className="flex flex-col gap-2">
        <div className="flex gap-2">
          <NumberInput
            label="Cena paketa"
            {...form.getInputProps("tierPrice")}
          />
          <NumberInput
            label="Število lokacij"
            {...form.getInputProps("maxLocationCount")}
          />
          <NumberInput
            label="Število uporabnikov"
            {...form.getInputProps("maxUserCount")}
          />
          <NumberInput
            label="Število resourcov"
            {...form.getInputProps("maxResourceCount")}
          />
          <NumberInput
            label="Število SMSjev"
            {...form.getInputProps("smsLimit")}
          />
        </div>
        <div className="flex flex-col gap-2">
          {data.features.map((feature) => (
            <Switch
              key={feature.featureId}
              label={feature.name}
              checked={form.values.features.includes(feature.featureId)}
              onChange={(event) => {
                if (event.currentTarget.checked) {
                  form.setFieldValue("features", [
                    ...form.values.features,
                    feature.featureId,
                  ]);
                } else {
                  form.setFieldValue(
                    "features",
                    form.values.features.filter(
                      (id) => id !== feature.featureId,
                    ),
                  );
                }
              }}
            />
          ))}
        </div>
        <Button type="submit">Submit</Button>
      </form>
    </div>
  );
};

export default PricingTierEdit;
