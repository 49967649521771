import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { DateRangeActions } from "@/Components/DateRangeActions";
import { LimeLoader } from "@/Components/LimeLoader";
import {
  PageHeader,
  PageHeaderProps,
} from "@/Components/PageHeader/PageHeader";
import { api } from "@/lib/api-client";
import { Space, Text, TextInput } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import dayjs from "dayjs";
import { useState } from "react";
import { RiSearch2Line } from "react-icons/ri";
import { Outlet, useNavigate } from "react-router";
import { CreateAccount } from "./CreateAccount";

const getCurrentTab = (pathName: string) => {
  const splitPath = pathName.split("/");
  const stripeIndex = splitPath.findIndex((p) => p === "stripe");
  const tab = splitPath[stripeIndex + 1];

  return tab;
};

const getCurrentSettingsSubTab = (pathName: string) => {
  const splitPath = pathName.split("/");
  const settingsIndex = splitPath.findIndex((p) => p === "settings");
  const tab = splitPath[settingsIndex + 1];

  return tab;
};

export type StripeOutletContextProps = {
  general: {
    dateRange: [string | undefined, string | undefined];
  };
  payments: {
    search: string;
  };
};

export const Stripe = () => {
  const activeTab = getCurrentTab(window.location.pathname);
  const activeSettingsSubTab = getCurrentSettingsSubTab(
    window.location.pathname,
  );

  const navigate = useNavigate();

  const {
    data: accountData,
    isPending,
    isSuccess,
  } = api.stripe.useGetStripeAccount();

  /* General tab states */
  const [dateRange, setDateRange] = useState<
    [string | undefined, string | undefined]
  >([undefined, undefined]);

  /* Payments overview tab states */
  const [paymentsSearch, setPaymentsSearch] = useDebouncedState<string>(
    "",
    400,
  );

  if (isPending) {
    return <LimeLoader />;
  }

  if (!isSuccess) {
    return (
      <Text>
        <Trans>Something went wrong</Trans>
      </Text>
    );
  }

  let subPageHeader: PageHeaderProps["subPageHeader"] = undefined;
  if (activeSettingsSubTab === "services") {
    subPageHeader = {
      title: t`Nastavitve spletnega plačevanja`,
      onBackButtonClick: () => navigate("./settings", { relative: "path" }),
    };
  }

  return (
    <div>
      {accountData.stripeAccountId == null && <CreateAccount />}

      {accountData.stripeAccountId != null && (
        <>
          <PageHeader
            title={t`Spletno plačevanje`}
            subHeader={{
              tabs: [
                {
                  label: t`Splošno`,
                  value: "general",
                  rightSection: (
                    <DateRangeActions
                      handleChangeDateRange={(startDate, endDate) => {
                        const startDateString = startDate
                          ? dayjs(startDate).format("YYYY-MM-DD")
                          : undefined;
                        const endDateString = endDate
                          ? dayjs(endDate).add(1, "day").format("YYYY-MM-DD")
                          : undefined;

                        setDateRange([startDateString, endDateString]);
                      }}
                      disabledDateRangeOptions={["1d", "all"]}
                    />
                  ),
                },
                {
                  label: t`Pregled plačil`,
                  value: "payments",
                  rightSection: (
                    <TextInput
                      placeholder={t`Išči naročilo`}
                      leftSection={<RiSearch2Line />}
                      defaultValue={paymentsSearch}
                      maw={"350px"}
                      w={"100%"}
                      onChange={(e) => setPaymentsSearch(e.currentTarget.value)}
                    />
                  ),
                },
                {
                  label: t`Nastavitve`,
                  value: "settings",
                },
              ],
              selectedTab: activeTab,
              onSelectTab: (value) => {
                navigate(value, {
                  relative: "path",
                });
              },
            }}
            subPageHeader={subPageHeader}
          />

          <Space h="md" />

          <Outlet
            context={{
              general: {
                dateRange,
              },
              payments: {
                search: paymentsSearch,
              },
            }}
          />
        </>
      )}
    </div>
  );
};
