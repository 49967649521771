import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { api } from "@/lib/api-client";
import { useForm } from "@mantine/form";
import {
  Button,
  Card,
  CardBody,
  Divider,
  Link,
  Radio,
  Slider,
  Spinner,
} from "@heroui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import qz from "qz-tray";
import { isArray, union } from "lodash";
import { LimeRadioGroup } from "@/Components/NextBase/LimeRadioGroup";
import { usePosPrinterStore } from "@/stores/pos-printer-store";
import { Download } from "lucide-react";
import { GetLocations, GetPosPrinterTypes } from "@/server-types";
import { toast } from "sonner";
import { isMobile } from "react-device-detect";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { TaxRegisterAuthorizationDialog } from "../AuthorizationDialog";
import { PrintableDocument } from "../../../../utils/pos-printer";
import { InvoiceDocument, preparePrintData } from "@/utils/pos-printer";

export const TaxRegisterSetupPrinters = () => {
  const navigate = useNavigate();

  const [discoveredPrinters, setDiscoveredPrinters] = useState<string[]>([]);
  const [isDiscoveringPrinters, setIsDiscoveringPrinters] = useState(false);

  const [isQzTrayRunning, setIsQzTrayRunning] = useState(true);

  const {
    data: locationsData,
    isFetching: isLocationsFetching,
    processedErrorMessage: getLocationsErrorMessage,
    refetch: refetchLocations,
  } = api.location.useGetLocations({
    page: 1,
    perPage: 100,
    sortBy: "labelDesc",
  });

  const {
    mutateAsync: downloadQzTrayClientCertificate,
    isPending: isDownloadingClientCertificate,
    processedErrorMessage: downloadQzTrayClientCertificateErrorMessage,
  } = api.storage.useDownloadQzTrayClientCertificate();

  const { setupQzTray, isQzTraySigningSetup } = usePosPrinterStore(
    (state) => state,
  );

  useEffect(() => {
    if (!isQzTraySigningSetup) return;

    discoverPrinters();
  }, [isQzTraySigningSetup]);

  const discoverPrinters = async () => {
    setIsQzTrayRunning(true);
    setIsDiscoveringPrinters(true);

    try {
      if (!isQzTraySigningSetup) {
        setupQzTray({});
      }

      if (!qz.websocket.isActive()) {
        await qz.websocket.connect();
      }
    } catch (e) {
      console.error("connect error ", e);

      setIsQzTrayRunning(false);
      setIsDiscoveringPrinters(false);
      // setDiscoveredPrinters([]);
      return;
    }

    try {
      const printers = await qz.printers.find();
      console.log("printers", printers);
      if (printers.length === 0) {
        setDiscoveredPrinters([]);
        setIsDiscoveringPrinters(false);
        return;
      }

      if (!isArray(printers)) {
        setDiscoveredPrinters([printers]);
      } else {
        setDiscoveredPrinters(printers);
      }

      setIsDiscoveringPrinters(false);
    } catch (error) {
      console.error(error);
      setIsDiscoveringPrinters(false);
      // setDiscoveredPrinters([]);
    }

    try {
      await qz.websocket.disconnect();
    } catch (error) {
      console.error("error disconnecting qztray", error);
    }
    setIsDiscoveringPrinters(false);
  };

  const onLocationUpdated = async () => {
    refetchLocations();
  };

  const testPrint = async ({
    doc,
    printerConfig,
  }: {
    doc: PrintableDocument;
    printerConfig?: GetLocations["response"]["locations"][number]["PosPrinter"];
  }) => {
    if (!printerConfig) {
      return;
    }

    const printData = await preparePrintData(doc, printerConfig);

    if (!qz.websocket.isActive()) {
      console.log("connecting to qztray");
      await qz.websocket.connect({
        host: printerConfig.qzTrayHostIp || undefined,
        retries: 5,
        delay: 1,
      });
    }

    const config = qz.configs.create(printerConfig.printerName, {
      copies: 1,
      jobName: `Test Receipt Print`,
      colorType: "blackwhite",
      duplex: false,
      margins: 0,
      rotation: 0,
      scaleContent: false,
    });
    console.log("print data", printData);
    await qz.print(config, [printData]);
  };

  const locationPrinters =
    locationsData?.locations
      .filter((l) => l.PosPrinter)
      .map((l) => l.PosPrinter!.printerName) || [];
  const discoveredAndLocationPrintersUnion = union(
    discoveredPrinters,
    locationPrinters,
  );

  console.log(
    "discoveredAndLocationPrintersUnion",
    discoveredAndLocationPrintersUnion,
  );
  console.log("discovered printers", discoveredPrinters);

  return (
    <>
      <TaxRegisterAuthorizationDialog
        handleSuccessfulAuthorization={async () => {
          refetchLocations();
        }}
        requiresSetupCompleted={false}
      />

      <LimePageHeader
        title={t`Vzpostavitev tiskalnikov`}
        subPage={{
          title: t`Tiskalniki`,
          onBackButtonClick: () => navigate("../", { relative: "path" }),
        }}
      />

      {!isQzTrayRunning && (
        <>
          <LimeAlert
            color="danger"
            className="mx-2 mt-4 md:mx-8"
            message={t`Preverite, da imate na napravi nameščen QZTray in je prižgan`}
          >
            <Link
              href="https://qz.io/download/"
              target="_blank"
              className="mt-4 text-inherit"
              showAnchorIcon
              underline="always"
            >
              <Trans>Namestitev QzTray</Trans>
            </Link>
          </LimeAlert>

          <Divider className="mt-4" />
        </>
      )}

      {isMobile && (
        <>
          <LimeAlert
            color="danger"
            className="mx-2 mt-4 md:mx-8"
            message={t`Spreminjanje tiskalnikov na mobilni napravi ni mogoče`}
          />

          <Divider className="mt-4" />
        </>
      )}

      <div className="px-2 pt-4 md:px-8">
        <Button
          onPress={async () => {
            const response = await downloadQzTrayClientCertificate();

            // Create a URL for the blob and download the file
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "qztray_lime_client.crt"); // Specify the file name
            document.body.appendChild(link);
            link.click();

            // Clean up the URL and link
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
          }}
          startContent={<Download />}
          color="primary"
          isLoading={isDownloadingClientCertificate}
        >
          <Trans>Prenesi QzTray certifikat</Trans>
        </Button>
        <LimeAlert
          color="danger"
          message={downloadQzTrayClientCertificateErrorMessage}
        />
        <p className="mt-4 text-foreground-500">Namestitev:</p>
        <p className="text-foreground-500">
          <Trans>
            QzTray -&gt; Advanced -&gt; Site Manager -&gt; &quot;+&quot; -&gt;
            Browse
          </Trans>
        </p>
      </div>
      <Divider className="mt-4" />

      <div className="mt-4 flex gap-2 px-2 md:px-8">
        <Button
          color="primary"
          onPress={async () => {
            const printerConfig = locationsData?.locations[0].PosPrinter;

            const data: InvoiceDocument["invoiceData"] = {
              numberOnly: 1234,
              paidInFull: true,
              additionalReceiptData: {
                activities: [],
                employeeData: {
                  name: "John Doe",
                },
                ZOI: "zoi",
                EOR: "eor",
                QR: "testQR",
                invoiceData: {
                  items: [
                    {
                      name: "Test",
                      quantity: 1,
                      unit: "kos",
                      price: 10,
                      taxRate: 9.5,
                      taxRateAbbreviation: "9.5%",
                      totalTax: 1,
                      total: 2,
                      totalWithTax: 3,
                      totalWithTaxFormatted: "3,00 EUR",
                      custom: {},
                      discount: undefined,
                    },
                  ],
                  invoiceTaxes: [],
                },
                _documentIssuer: {
                  name: "Issuer",
                  address: "Vojkova 63",
                  address2: "a",
                  country: "Slovenia",
                  taxNumber: "21212121",
                  isTaxSubject: true,
                  zip: "2000",
                  city: "Ljubljana",
                  IBAN: "SI56IBAN",
                  SWIFT: "SWIFT",
                },
                customerData: {
                  name: "Jonas Does",
                  address: "Vojkova 63",
                  city: "Ljubljana",
                  taxNumber: "12345678",
                  zip: "2000",
                },
                payments: [],
                printCount: 1,
              },
              canceled: false,
              number: "-1",
              totalWithoutDiscount: "0",
              totalDiscountFormatted: "0",
              currencyId: "EUR",
              total: "3,00 EUR",
              date: "1. 1. 2025",
              location: {
                city: "Ljubljana",
              },
              totalWithTax: 3,
              totalDiscount: 0,
              cancellationReason: undefined,
              _documentClient: {
                companyData: {
                  companyName: "Lime",
                  taxNumber: "12345678",
                },
                customerData: {
                  name: "John Doe",
                },
              },
              customer: "",
              dateDue: "1. 1. 2025",
              id: "1",
              locationId: 1,
              reference: "123456789",
              totalDue: 0,
              totalDueFormatted: "0",
              totalFormatted: "0",
              status: {
                label: "Plačano",
                value: "paid",
              },
              custom: {
                locationId: "1",
                user: {
                  name: "John Doe",
                  lastName: "Doe",
                  countryCode: "386",
                  taxNumber: "12345678",
                  userId: "1",
                },
                createGiftCardPayments: [],
              },
              giftCards: [],
            };

            await testPrint({
              printerConfig,
              doc: {
                type: "invoice",
                invoiceData: data,
                isCopy: false,
              },
            });
          }}
        >
          <Trans>Preizkusi printanje računa</Trans>
        </Button>
        {/* <Button
          color="primary"
          onPress={async () => {
            const printerConfig = locationsData?.locations[0].PosPrinter;

            if (!printerConfig) {
              return;
            }

            const data = {
              operatorLabel: "operatorLabel",
              giftCard: {
                code: "BW215D",
                initialAmountCents: 1000,
                expiryDate: "2025-01-05",
                issueDate: "2025-01-01",
                amountLeftCents: 1000,
                amountLeftFormatted: "10,00 EUR",
                initialAmountFormatted: "10,00 EUR",
              },
              currencyId: "EUR",
              organization: {
                name: "name",
                address: "address",
                address2: "address2",
                city: "city",
                taxNumber: "taxNumber",
                zip: "zip",
              },
            };

            await testPrint({
              printerConfig,
              doc: {
                type: "gift-card",
                data,
              },
            });
          }}
        >
          Test print darilni bon
        </Button> */}
        {/* <Button
          color="primary"
          onPress={async () => {
            const printerConfig = locationsData?.locations[0].PosPrinter;

            if (!printerConfig) {
              return;
            }

            const data = {
              operatorLabel: "operatorLabel",
              organization: {
                name: "name",
                address: "address",
                address2: "address2",
                city: "city",
                taxNumber: "taxNumber",
                zip: "zip",
              },
              payments: {
                cash: {
                  amountFormatted: "1,00 EUR",
                  amountCents: 1000,
                },
              },
              issuedGiftCards: [
                {
                  initialAmountFormatted: "1,00 EUR",
                  initialAmountCents: 1000,
                },
              ],
              currencyId: "EUR",
            };

            await testPrint({
              printerConfig,
              doc: {
                type: "gift-card-receipt",
                data,
              },
            });
          }}
        >
          Test print račun darilni bon
        </Button> */}
      </div>

      <Divider className="mt-4" />

      <div className="mb-4 mt-4 flex flex-col gap-4 px-2 md:px-8">
        {isLocationsFetching && <Spinner />}

        {locationsData?.locations.map((loc) => {
          return (
            <LocationPrinterConfig
              discoveredAndLocationPrintersUnion={
                discoveredAndLocationPrintersUnion
              }
              discoveredPrinters={discoveredPrinters}
              isDiscoveringPrinters={isDiscoveringPrinters}
              location={loc}
              onLocationUpdated={onLocationUpdated}
            />
          );
        })}
      </div>
    </>
  );
};

type LocationPrinterConfigForm = {
  locationId: number;
  printerName?: string;
  printerType?: GetPosPrinterTypes["response"][number];
  lineWidth?: number;
  qzTrayHostIp?: string;
  htmlRootFontSize: number;
};

const LocationPrinterConfig = ({
  location,

  discoveredPrinters,
  discoveredAndLocationPrintersUnion,
  isDiscoveringPrinters,
  onLocationUpdated,
}: {
  location: GetLocations["response"]["locations"][number];

  discoveredPrinters: string[];
  discoveredAndLocationPrintersUnion: string[];
  isDiscoveringPrinters: boolean;
  onLocationUpdated: () => void;
}) => {
  const {
    mutateAsync: putLocation,
    isPending: isPutLocationPending,
    processedErrorMessage: putLocationErrorMessage,
  } = api.location.usePutLocation();

  const {
    data: printerTypes,
    isFetching: isFetchingPrinterTypes,
    processedErrorMessage: fetchPrinterTypesErrorMessage,
  } = api.values.useGetPosPrinterTypes();

  const handleSubmit = async (values: LocationPrinterConfigForm) => {
    const updateLocationPromise = putLocation({
      locationId: location.locationId,
      body: {
        printerConfig: {
          ...values,
          printerName: values.printerName!,
          printerType: values.printerType!,
          lineWidth: values.lineWidth!,
          htmlRootFontSize: values.htmlRootFontSize,
        },
      },
    });

    toast.promise(updateLocationPromise, {
      error: (data) => {
        return data || t`Napaka pri posodabljanju konfiguracije tiskalnika`;
      },
      success: (data) => {
        onLocationUpdated();
        form.resetDirty();
        return t`Konfiguracija tiskalnika uspešno posodobljena`;
      },
    });
  };

  const form = useForm<LocationPrinterConfigForm>({
    initialValues: {
      locationId: location.locationId,
      ...location.PosPrinter,
      qzTrayHostIp: location.PosPrinter?.qzTrayHostIp || undefined,
      htmlRootFontSize: location.PosPrinter?.htmlRootFontSize || 12,
    },

    validateInputOnChange: ["qzTrayHostIp"],

    validate: {
      printerName: (value) => {
        if (!value || value.length < 1) return t`Tiskalnik je obvezen`;
      },

      printerType: (value) => {
        if (!value) return t`Tip tiskalnika je obvezen`;
      },

      lineWidth: (value) => {
        if (!value) return t`Širina traku je obvezna`;
      },

      qzTrayHostIp: (value) => {
        if (!value) return;
        if (
          !/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(
            value,
          )
        ) {
          return true;
        }
      },
    },
  });

  // useEffect(() => {
  //   if (!location) return;

  //   form.setValues({
  //     ...location,
  //     ...location.PosPrinter,
  //     qzTrayHostIp: location.PosPrinter?.qzTrayHostIp || undefined,
  //   });
  // }, [location]);

  return (
    <Card shadow="sm" isDisabled={isPutLocationPending}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <CardBody className="gap-4">
          <p className="font-semibold">{location.label}</p>
          <LimeSelect
            isLoading={isDiscoveringPrinters}
            isDisabled={!discoveredPrinters.length}
            items={discoveredAndLocationPrintersUnion.map((dp) => ({
              label: dp,
              key: dp,
            }))}
            label={t`Tiskalnik`}
            {...form.getInputProps("printerName")}
          />

          <LimeSelect
            isLoading={isFetchingPrinterTypes}
            items={
              printerTypes?.map((pt) => ({
                key: pt,
                label: pt,
              })) ?? []
            }
            label={t`Vrsta tiskalnika`}
            {...form.getInputProps("printerType")}
          />
          <LimeAlert color="danger" message={fetchPrinterTypesErrorMessage} />

          <LimeRadioGroup
            label={t`Število stolpcev na termalnem traku`}
            {...form.getInputProps("lineWidth")}
            value={form.getValues().lineWidth?.toString()}
          >
            <Radio value={"33"}>33 stolpcev</Radio>
            <Radio value={"48"}>48 stolpcev</Radio>
          </LimeRadioGroup>

          {/* <LimeInput
            label={t`Lokalni IP naprave, na katero je tiskalnik povezan`}
            {...form.getInputProps("qzTrayHostIp")}
            isDisabled
          /> */}

          {form.getValues().printerType === "star_mi" ? (
            <Slider
              className="max-w-md"
              color="primary"
              value={form.getValues().htmlRootFontSize}
              onChange={(value) =>
                form.setFieldValue("htmlRootFontSize", value as number)
              }
              label={t`Velikost pisave`}
              maxValue={40}
              minValue={10}
              showSteps={true}
              size="sm"
              step={1}
            />
          ) : undefined}

          <Button
            type="submit"
            color="primary"
            isLoading={isPutLocationPending}
            className="w-fit"
          >
            <Trans>Shrani</Trans>
          </Button>

          <LimeAlert color="danger" message={putLocationErrorMessage} />
        </CardBody>
      </form>
    </Card>
  );
};
