import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Anchor, Box, Button, Flex, Text, TextInput } from "@mantine/core";
import { useState } from "react";
import { Link } from "react-router";
import { apiForgotPassword } from "../../../apiCalls";

const ForgotPassword = () => {
  const [submitted, setSubmitted] = useState(false);
  const [emailDoesntExsist, setEmailDoesntExsist] = useState(false);
  const [email, setEmail] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    // Check if the email is in the database and if it is, send an email with a password reset link
    try {
      await apiForgotPassword({ email });
      setSubmitted(true);
    } catch (err) {
      setEmailDoesntExsist(true);
    }
  };

  return (
    <Box maw={"650px"} m={"0 auto"} ta={"left"} w={"100%"} px={"md"}>
      <Link
        to={"/login"}
        style={{ position: "absolute", left: "2rem", top: "2rem" }}
      >
        <img
          style={{ height: "50px" }}
          src="/public/logo_full_green.svg"
          alt=""
        />
      </Link>
      <div
        style={{ height: "80px", width: "200px", left: "2rem", top: "2rem" }}
      >
        &nbsp;
      </div>
      {!submitted ? (
        <>
          <Flex direction={"column"} gap={"md"} mt={"3rem"}>
            <Text variant={TextVariant.Heading}>
              <Trans>Ponastavi svoje geslo</Trans>
            </Text>
            <Text variant={TextVariant.Body}>
              <Trans>
                Vnesite elektronski naslov, povezan z vašim računom, in poslali
                vam bomo povezavo za ponastavitev gesla.
              </Trans>
            </Text>
            <form style={{ width: "100%" }}>
              <TextInput
                label={t`E-mail naslov`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="filled"
                mb={"md"}
              />

              {emailDoesntExsist && (
                <Text c={Color.Error} variant={TextVariant.Body} mb={"1rem"}>
                  <Trans>
                    Račun s tem e-mailom ne obstaja. Prosimo poskusite ponovno.
                  </Trans>
                </Text>
              )}

              <Button
                onClick={onSubmit}
                disabled={!(email.includes("@") && email.includes("."))}
                fullWidth
              >
                <Trans>Nadaljuj</Trans>
              </Button>
            </form>

            <Anchor
              variant={TextVariant.Body}
              href="/login"
              ta={"center"}
              w={"100%"}
              mt={"md"}
            >
              <Trans>Nazaj na prijavo</Trans>
            </Anchor>
          </Flex>
          <Flex w={"100%"} justify={"center"} align={"center"}>
            <Anchor variant={TextVariant.Body} href="/register">
              <Trans>Še nimate računa? Registrirajte se!</Trans>
            </Anchor>
          </Flex>
        </>
      ) : (
        <>
          <Flex
            direction={"column"}
            style={{
              padding: "2rem",
            }}
            mt={"3rem"}
          >
            <Text variant={TextVariant.Subheading} mb={"sm"}>
              <Trans>
                Hvala, preverite svoj elektronski naslov za navodila za
                ponastavitev gesla.
              </Trans>
            </Text>
            <Text variant={TextVariant.Body}>
              <Trans>
                Če niste prejeli elektronskega sporočila v 5 minutah, preverite
                med vsiljeno pošto ali pošljite ponovno
              </Trans>
            </Text>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default ForgotPassword;
