import { Navigate, Route, Routes, useLocation } from "react-router";
import CalendarPage from "./Calendar/CalendarPage";
import Completed from "./Completed/Completed";
import Locations from "./Locations/Locations";
import Schedule from "./Schedule/Schedule";
import Users from "./Users/Users";

import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import * as Sentry from "@sentry/react";
import { Analytics } from "./Analytics/Analytics";
import ChangeLog from "./ChangeLog/ChangeLog";
import { ClientSettings } from "./ClientSettings/ClientSettings";
import { LimeAdmin } from "./LimeAdmin/LimeAdmin";
import { NotificationSettings } from "./NotificationSettings/NotificationSettings";
import { ManageResource } from "./ResourceGroups/ManageResource";
import { ManageResourceGroup } from "./ResourceGroups/ManageResourceGroup";
import { ResourceGroups } from "./ResourceGroups/ResourceGroups";
import { GeneralTab as ResourceGroupGeneralTab } from "./ResourceGroups/Tabs/GeneralTab";
import { ResourcesTab as ResourceGroupResourcesTab } from "./ResourceGroups/Tabs/ResourcesTab";
import { ScheduleTab as ResourceGroupScheduleTab } from "./ResourceGroups/Tabs/ScheduleTab";
import { ServicesTab as ResourceGroupServicesTab } from "./ResourceGroups/Tabs/ServicesTab";
import { ManageCategory } from "./Services/ManageCategory";
import { ManageService } from "./Services/ManageService";
import { ServiceCategories } from "./Services/ServiceCategories";
import { Services } from "./Services/Services";
import { AccountSettings as StripeAccountSettings } from "./StripeSettings/AccountSettings";
import { GeneralTab as StripeGeneralTab } from "./StripeSettings/GeneralTab";
import { PaymentsOverviewTab as StripePaymentsOverviewTab } from "./StripeSettings/PaymentsOverviewTab";
import { ServicePaymentSettings as StripeServicePaymentSettings } from "./StripeSettings/ServicePaymentSettings";
import { Stripe } from "./StripeSettings/Stripe";
import { Integrations } from "./Integrations/Integrations";
import { FacebookConversionApi } from "./Integrations/FacebookConversionApi";
import { TaxRegisterSetup } from "./TaxRegister/Setup/Setup";
import { TaxRegisterSetupBasicInfo } from "./TaxRegister/Setup/BasicInfo";
import { TaxRegisterSetupCertificate } from "./TaxRegister/Setup/Certificate";
import { TaxRegisterSetupBusinessPremise } from "./TaxRegister/Setup/BusinessPremise/BusinessPremise";
import { TaxRegisterSetupElectronicDevice } from "./TaxRegister/Setup/ElectronicDevice/ElectronicDevice";
import { TaxRegisterInvoices } from "./TaxRegister/Invoices/Invoices";
import { POS } from "./TaxRegister/Invoices/POS/POS";
import { TaxRegisterSetupAct } from "./TaxRegister/Setup/Act";
import { TaxRegisterSetupPrinters } from "./TaxRegister/Setup/Printers";
import { CashRegister } from "./TaxRegister/CashRegister/CashRegister";
import { Products } from "./Products/Products";
import { ManageProduct } from "./Products/ManageProduct";
import { ManageProductCategory } from "./Products/ManageProductCategory";
import { DeliveryOrders } from "./DeliveryOrders/DeliveryOrders";
import { ManageDeliveryOrder } from "./DeliveryOrders/ManageDeliveryOrder/ManageDeliveryOrder";
import { GiftCard } from "./GiftCard/GiftCard";
import { GiftCardDetails } from "./GiftCard/GiftCardDetails";
import { ProductStock } from "./ProductStock/ProductStock";
import { CashRegisterClosure } from "./TaxRegister/CashRegister/Closure";
import { CashRegisterStatus } from "./TaxRegister/CashRegister/Status";
import { StockTaking } from "./StockTaking/StockTaking";
import StockTakingSession from "./StockTaking/StockTakingSession";
import useFeature from "@/hooks/useFeature";
import useAccountInfo from "@/hooks/useAccountInfo";
import { cn } from "@/utils";

export const DashboardRoutes = () => {
  const location = useLocation();

  const { isFeatureEnabled } = useFeature();

  const accountInfo = useAccountInfo((state) => state.accountInfo);

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const ignoreContentPaddingPathNames = [
    "/dashboard/calendar",
    "/dashboard/resource-groups",
    "/dashboard/services",
    "/dashboard/notifications",
    "/dashboard/settings",
    "/dashboard/locations",
    "/dashboard/users",
    "/dashboard/integrations",
    "/dashboard/tax-register",
    "/dashboard/products",
    "/dashboard/delivery-orders",
    "/dashboard/gift-card",
    "/dashboard/products/stock",
    "/dashboard/stock-taking",
    "/dashboard/products/internal-use",
  ];
  const ignoreContentPadding = ignoreContentPaddingPathNames.filter((path) =>
    location.pathname.startsWith(path),
  );

  const ignoreBottomPaddingPathNames = ["/dashboard/tax-register"];
  const ignoreBottomPadding = ignoreBottomPaddingPathNames.filter((path) =>
    location.pathname.startsWith(path),
  );

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <div
      className={cn(
        "relative z-20 min-h-screen w-full flex-1 overflow-visible bg-white px-4 pb-16",
        "md:h-screen md:overflow-scroll md:px-12 md:py-4",
        {
          "pb-0 md:pb-0": ignoreBottomPadding.length > 0,
        },
        {
          "p-0 px-0 py-0 md:p-0 md:px-0 md:py-0":
            ignoreContentPadding.length > 0,
        },
      )}
    >
      <SentryRoutes>
        {permissions.view_settings_page && (
          <Route path="/settings" element={<ClientSettings />}></Route>
        )}
        {permissions.view_users_page && (
          <Route path="/users" element={<Users></Users>}></Route>
        )}
        {permissions.view_analytics && (
          <Route path="/analytics" element={<Analytics></Analytics>}></Route>
        )}
        {permissions.view_locations_page && (
          <Route path="/locations" element={<Locations></Locations>}></Route>
        )}
        {permissions.view_services_page && (
          <>
            <Route path="/services" element={<Services />} />
            <Route
              path="/services/categories"
              element={<ServiceCategories />}
            />

            <Route path="/services/:id" element={<ManageService />} />
            <Route
              path="/services/categories/:id"
              element={<ManageCategory />}
            />
          </>
        )}

        <Route path="/calendar" element={<CalendarPage></CalendarPage>}></Route>
        {permissions.view_customer_list && (
          <Route path="/completed" element={<Completed />}></Route>
        )}
        {permissions.view_availability && (
          <Route path="/schedule" element={<Schedule></Schedule>}></Route>
        )}
        {permissions.view_notifications_page && (
          <Route
            path="/notifications/*"
            element={<NotificationSettings></NotificationSettings>}
          ></Route>
        )}
        {["admin", "salesman"].includes(accountInfo.systemRole) && (
          <Route path="/admin/*" element={<LimeAdmin></LimeAdmin>}></Route>
        )}
        {isFeatureEnabled("CHANGELOG") && permissions.view_change_log_page && (
          <Route path="/change-log" element={<ChangeLog></ChangeLog>}></Route>
        )}
        {isFeatureEnabled("PAYMENTS") && (
          <Route path="/stripe" element={<Stripe />}>
            <Route index element={<Navigate to="general" replace />} />
            <Route path="general" element={<StripeGeneralTab />} />
            <Route path="payments" element={<StripePaymentsOverviewTab />} />
            <Route path="settings" element={<StripeAccountSettings />} />
            <Route
              path="settings/services"
              element={<StripeServicePaymentSettings />}
            />
          </Route>
        )}
        {isFeatureEnabled("RESOURCES") && (
          <>
            <Route path="resource-groups" element={<ResourceGroups />} />
            <Route path="resource-groups/:id" element={<ManageResourceGroup />}>
              <Route index element={<Navigate to="general" replace />} />
              <Route path="general" element={<ResourceGroupGeneralTab />} />
              <Route path="services" element={<ResourceGroupServicesTab />} />
              <Route path="schedule" element={<ResourceGroupScheduleTab />} />
              <Route
                path="resources/:resourceId"
                element={<ManageResource />}
              />
              <Route path="resources" element={<ResourceGroupResourcesTab />} />
            </Route>
          </>
        )}
        {isFeatureEnabled("INTEGRATIONS") && (
          <>
            <Route path="integrations" element={<Integrations />}>
              <Route path="fbcapi" element={<FacebookConversionApi />} />
            </Route>
          </>
        )}

        {isFeatureEnabled("GIFTCARD") && (
          <>
            <Route path="gift-card" element={<GiftCard />} />
            <Route path="gift-card/:id" element={<GiftCardDetails />} />
          </>
        )}

        {isFeatureEnabled("TAX_REGISTER") && (
          <>
            {permissions.view_invoice_archive_page && (
              <Route
                path="/tax-register/invoice/:invoiceId"
                element={<TaxRegisterInvoices />}
              ></Route>
            )}
            {permissions.view_pos_page && (
              <Route path="/tax-register/invoice/new" element={<POS />}></Route>
            )}
            {permissions.view_invoice_archive_page && (
              <Route
                path="/tax-register/invoice"
                element={<TaxRegisterInvoices />}
              ></Route>
            )}

            {(permissions.view_cash_register_status_summary_view ||
              permissions.view_cash_register_closures ||
              permissions.allow_closing_cash_register) && (
              <Route
                path="/tax-register/cash-register"
                element={<CashRegister />}
              >
                {permissions.view_cash_register_status_summary_view && (
                  <Route
                    path=":businessPremiseId/status"
                    element={<CashRegisterStatus />}
                    index
                  />
                )}
                {(permissions.allow_closing_cash_register ||
                  permissions.view_cash_register_closures) && (
                  <Route
                    path=":businessPremiseId/closure"
                    element={<CashRegisterClosure />}
                  />
                )}
              </Route>
            )}

            {permissions.view_tax_register_settings_page && (
              <>
                <Route
                  path="/tax-register/settings/setup"
                  element={<TaxRegisterSetup />}
                ></Route>
                <Route
                  path="/tax-register/settings/setup/info"
                  element={<TaxRegisterSetupBasicInfo />}
                ></Route>
                <Route
                  path="/tax-register/settings/setup/cert"
                  element={<TaxRegisterSetupCertificate />}
                ></Route>
                <Route
                  path="/tax-register/settings/setup/business-premise"
                  element={<TaxRegisterSetupBusinessPremise />}
                ></Route>
                <Route
                  path="/tax-register/settings/setup/electronic-devices"
                  element={<TaxRegisterSetupElectronicDevice />}
                ></Route>
                <Route
                  path="/tax-register/settings/setup/act"
                  element={<TaxRegisterSetupAct />}
                ></Route>
                <Route
                  path="/tax-register/settings/setup/printers"
                  element={<TaxRegisterSetupPrinters />}
                ></Route>
              </>
            )}

            {isFeatureEnabled("PRODUCTS") && permissions.view_products_page && (
              <>
                <Route
                  path="/products"
                  element={<Products tab="products" />}
                ></Route>
                <Route
                  path="/products/categories"
                  element={<Products tab="categories" />}
                ></Route>

                <Route
                  path="/products/categories/:categoryId"
                  element={<ManageProductCategory />}
                ></Route>
                <Route
                  path="/products/:productId"
                  element={<ManageProduct />}
                ></Route>
              </>
            )}

            {isFeatureEnabled("PRODUCTS") &&
              permissions.view_stock_taking_page && (
                <>
                  <Route path="/stock-taking" element={<StockTaking />}></Route>
                  <Route
                    path="/stock-taking/:stockTakingSessionId"
                    element={<StockTakingSession />}
                  ></Route>
                </>
              )}

            {isFeatureEnabled("PRODUCTS") &&
              permissions.view_delivery_orders_page && (
                <>
                  <Route
                    path="/delivery-orders"
                    element={<DeliveryOrders />}
                  ></Route>
                  <Route
                    path="/delivery-orders/:deliveryOrderId"
                    element={<ManageDeliveryOrder />}
                  ></Route>
                  <Route
                    path="/delivery-orders/:deliveryOrderId"
                    element={<ManageDeliveryOrder key={"deliveryOrder"} />}
                  ></Route>
                </>
              )}

            {isFeatureEnabled("PRODUCTS") &&
              permissions.view_product_stock_page && (
                <>
                  <Route
                    path="/products/stock"
                    element={<ProductStock></ProductStock>}
                  ></Route>
                </>
              )}
          </>
        )}

        <Route path={"*"} element={<Navigate to={"/dashboard/calendar"} />} />
      </SentryRoutes>
    </div>
  );
};
