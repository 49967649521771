import { useSearchParams } from "react-router";
import styled from "styled-components";
import { useCalendarStore } from "../../store/useCalendarStore";
import { ResourceColumn } from "./ResourceColumn/ResourceColumn";
import UserColumn from "./UserColumn";

const DayColumn = ({ day, timeoffs, isOnlyDay }) => {
  const { calendarMode, resources } = useCalendarStore((state) => state);

  const [params, setParams] = useSearchParams();

  const resourcesInOrder = resources.filter((r) => r.isEnabledInFilter);
  const usersInOrder = day.users;

  return (
    <Wrapper className={"dayColumn"} isOnlyDay={isOnlyDay}>
      {calendarMode === "users" && (
        <div className={"users"}>
          {day.users
            .filter(
              (u) => !params.get("u") || u.userId === Number(params.get("u")),
            )
            .map((user, index) => {
              return (
                <UserColumn
                  isLastChild={index === day.users.length - 1}
                  user={user}
                  key={index}
                  date={day.date}
                  timeoffs={timeoffs.filter((t) => t.userId === user.userId)}
                  usersInOrder={usersInOrder.map((u) => u.userId)}
                ></UserColumn>
              );
            })}
        </div>
      )}
      {calendarMode === "resources" && (
        <div className="resources">
          {resourcesInOrder
            .filter((r) => !params.get("r") || r.id === Number(params.get("r")))
            .map((resource) => (
              <ResourceColumn
                resource={resource}
                date={day.date}
                resourcesInOrder={resourcesInOrder.map((r) => r.id)}
                key={resource.id}
              ></ResourceColumn>
            ))}
        </div>
      )}
      {day.disableOnHolidays ? (
        <HolidayDisabledOverlay></HolidayDisabledOverlay>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  flex: 1;

  ${(props) => {
    if (!props.isOnlyDay) {
      return `
      border-right: 2px solid #c3c5c6;
  :not(:last-child) {
    border-right: 3px solid #8c8c8c;
  }

  @media (max-width: 768px) {
    width: fit-content;
  }
      `;
    }
  }}

  .users, .resources {
    display: flex;
  }
`;

const HolidayDisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: repeating-linear-gradient(
    159.5deg,
    transparent,
    transparent 8px,
    rgb(243, 244, 247) 8px,
    rgb(243, 244, 247) 18px
  );

  background-color: white;

  pointer-events: none;

  z-index: -2;
`;

export default DayColumn;
