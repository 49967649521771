import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import {
  LimePageHeader,
  LimePageHeaderProps,
} from "@/Components/LimePageHeader";
import { useLimeAlertModal } from "@/Components/Modals/useLimeAlertModal";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { api } from "@/lib/api-client";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Text } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import {
  Button,
  Skeleton,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@heroui/react";
import React, { useEffect } from "react";
import { VscTrash } from "react-icons/vsc";
import { useNavigate, useParams } from "react-router";
import { toast } from "sonner";
import { z } from "zod";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";

const stockTakingSessionSchema = z.object({
  products: z.array(
    z.object({
      productId: z.number(),
      expectedQuantity: z.number(),
      actualQuantity: z.coerce.number(),
    }),
  ),
  comments: z.string().optional(),
});

const StockTakingSession = () => {
  const navigate = useNavigate();
  const { stockTakingSessionId } = useParams();

  // APIS
  const {
    data: sessionData,
    isError,
    isLoading: isExistingSessionLoading,
  } = api.stockTaking.useGetStockTakingSessionById(
    Number(stockTakingSessionId),
  );

  const { data: products, isLoading: isFetchingProducts } =
    api.product.useGetProducts({});

  const {
    mutateAsync: putStockTakingSession,
    processedErrorMessage: putStockTakingSessionErrorMessage,
    isPending: isPuttingStockTakingSession,
  } = api.stockTaking.usePutStockTakingSessionById();

  const {
    mutateAsync: endSession,
    processedErrorMessage: endSessionErrorMessage,
    isPending: isEndingSession,
  } = api.stockTaking.useEndStockTakingSession();

  const {
    mutateAsync: deleteStockTakingSession,
    processedErrorMessage: deleteStockTakingSessionErrorMessage,
    isPending: isDeletingStockTakingSession,
  } = api.stockTaking.useDeleteStockTakingSession();
  //

  const { alertModal } = useLimeAlertModal();

  const form = useForm<z.infer<typeof stockTakingSessionSchema>>({
    initialValues: {
      products: [],
    },
    validate: zodResolver(stockTakingSessionSchema),
  });

  useEffect(() => {
    if (sessionData) {
      form.initialize({ products: sessionData.stockTakingSessionProducts });
    }
    if (isError) {
      navigate("/dashboard/stock-taking");
    }
  }, [sessionData, isError]);

  const handleSubmit = async (
    values: z.infer<typeof stockTakingSessionSchema>,
    event?: React.FormEvent<HTMLFormElement>,
  ) => {
    const submittedButton = (
      event?.nativeEvent as unknown as {
        submitter: { value: "save" | "endSession" };
      }
    ).submitter.value;

    if (submittedButton === "endSession") {
      return handleEndSession(values);
    }

    if (submittedButton === "save") {
      return handleSave(values);
    }
  };

  const handleSave = async (
    values: z.infer<typeof stockTakingSessionSchema>,
  ) => {
    const parsedValues = stockTakingSessionSchema.parse(values);

    const products = parsedValues.products;

    await putStockTakingSession({
      body: {
        ...parsedValues,
        products,
      },
      sessionId: Number(stockTakingSessionId),
    });

    toast.success(t`Shranjeno`);
  };

  const handleEndSession = async (
    values: z.infer<typeof stockTakingSessionSchema>,
  ) => {
    const parsedValues = stockTakingSessionSchema.parse(values);
    await putStockTakingSession({
      body: { ...parsedValues, products: parsedValues.products },
      sessionId: Number(stockTakingSessionId),
    });

    await endSession(Number(stockTakingSessionId));

    toast.success(t`Inventura končana`);
    navigate("/dashboard/stock-taking");
  };

  const isEnded = !!sessionData?.dateEnded;

  const isLoading =
    isExistingSessionLoading || isFetchingProducts || !sessionData;

  const isButtonLoadingState =
    isPuttingStockTakingSession ||
    isEndingSession ||
    isDeletingStockTakingSession ||
    isLoading;

  const subPage: LimePageHeaderProps["subPage"] = {
    title: t`Inventura`,
    onBackButtonClick: () => navigate("/dashboard/stock-taking"),

    rightSection: !isEnded
      ? {
          options: [
            {
              label: t`Izbriši inventuro`,
              color: Color.Error,
              icon: <VscTrash />,
              onClick: async () => {
                if (stockTakingSessionId != null) {
                  alertModal({
                    title: t`Ali ste prepričani, da želite izbrisati inventuro?`,
                    onConfirm: async () => {
                      await deleteStockTakingSession(
                        Number(stockTakingSessionId),
                      );

                      toast.success(t`Inventura je bila izbrisana`);

                      navigate("/dashboard/stock-taking");
                    },
                    isDanger: true,
                  });
                }
              },
            },
          ],
        }
      : undefined,
  };

  return (
    <>
      <LimePageHeader subPage={subPage} title="" />
      <div className="flex flex-col px-4 py-4 md:px-8">
        <div className="flex justify-between">
          <Skeleton isLoaded={!isLoading} className="rounded">
            <Text variant={TextVariant.Subheading}>
              {isLoading ? "X" : sessionData.location}
            </Text>
          </Skeleton>
          <div className="flex flex-col">
            <Skeleton isLoaded={!isLoading} className="rounded">
              <Text variant={TextVariant.Caption}>
                {isLoading
                  ? "X"
                  : `${t`Začel`}: ${sessionData.createdByUserName}`}
              </Text>
            </Skeleton>

            {isEnded && !isLoading && (
              <Text variant={TextVariant.Caption}>
                {t`Zaključil`}: {sessionData.closedByUserName}
              </Text>
            )}
          </div>
        </div>

        <Skeleton isLoaded={!isLoading} className="w-fit rounded">
          <Text variant={TextVariant.Body} className="w-fit">
            {isLoading
              ? "X"
              : `${sessionData.dateStarted} ${isEnded ? ` - ${sessionData.dateEnded}` : ""}`}
          </Text>
        </Skeleton>

        <LimeAlert
          color="danger"
          message={
            putStockTakingSessionErrorMessage ||
            endSessionErrorMessage ||
            deleteStockTakingSessionErrorMessage
          }
        />
        <form
          onSubmit={form.onSubmit(handleSubmit)}
          className="mt-4 flex flex-col gap-4"
        >
          <Table removeWrapper>
            <TableHeader>
              <TableColumn>
                <Trans>Produkt</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Pričakovana zaloga</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Dejanska zaloga</Trans>
              </TableColumn>
            </TableHeader>
            <TableBody isLoading={isLoading} loadingContent={<Spinner />}>
              {form.values.products.map((item, index) => (
                <TableRow key={item.productId}>
                  <TableCell>
                    {
                      products?.products.find((p) => p.id === item.productId)
                        ?.name
                    }
                  </TableCell>
                  <TableCell>{item.expectedQuantity}</TableCell>
                  <TableCell>
                    {isEnded ? (
                      item.actualQuantity
                    ) : (
                      <LimeInput
                        type="number"
                        {...form.getInputProps(
                          `products.${index}.actualQuantity`,
                        )}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {!isEnded && sessionData && (
            <div className="flex gap-4">
              <Button
                type="submit"
                color="primary"
                value="save"
                isLoading={isButtonLoadingState}
              >
                <Trans>Shrani</Trans>
              </Button>
              <Button
                color="primary"
                disabled={form.values.products.some(
                  (p) => p.actualQuantity == null,
                )}
                type="submit"
                value="endSession"
                isLoading={isButtonLoadingState}
              >
                <Trans>Shrani in končaj inventuro</Trans>
              </Button>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default StockTakingSession;
