import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { api } from "@/lib/api-client";
import {
  Button,
  Divider,
  Pagination,
  Skeleton,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
} from "@heroui/react";
import { useCallback, useEffect, useState } from "react";
import { ClosingDetailsModal } from "./ClosingDetailsModal";
import { GetCashRegisterClosures } from "@/server-types";
import { Trash2 } from "lucide-react";
import { AlertButton } from "@/Components/NextBase/AlertButton";
import { toast } from "sonner";
import { useParams } from "react-router";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";

export type ClosureForm = {
  userId: number | null;
  PIN: string;
};

const perPage = 15;
export const CashRegisterClosure = () => {
  const { businessPremiseId } = useParams();

  const { data: posAuthData } = api.cookie.useGetPosToken();

  const { data: permissions } = api.user.useGetUserPermissions({
    query: { tokenType: "POS" },
    disabled: !posAuthData?.userId,
  });

  const {
    mutateAsync: closeCashRegister,
    isPending: isClosingCashRegister,
    processedErrorMessage: closeCashRegisterErrorMessage,
  } = api.taxRegister.usePostCashRegisterClosure();

  const [page, setPage] = useState(1);

  const [selectedClosureId, setSelectedClosureId] = useState<
    number | undefined
  >(undefined);

  const {
    data: closures,
    isFetching: isClosuresFetching,
    processedErrorMessage: closuresErrorMessage,
    refetch: refetchClosures,
  } = api.taxRegister.useGetCashRegisterClosures({
    businessPremiseId: businessPremiseId!,
    query: {
      pagination: {
        page,
        perPage,
      },
    },
    disabled: !permissions?.view_cash_register_closures,
  });

  const {
    mutateAsync: deleteClosure,
    isPending: isDeletingClosure,
    processedErrorMessage: deleteClosureErrorMessage,
  } = api.taxRegister.useDeleteCashRegisterClosure({
    businessPremiseId: businessPremiseId!,
  });

  /**
   * This is required for pagination to work correctly,
   * since it breaks if pageCount goes undefined between
   */
  const [pageCount, setPageCount] = useState(1);
  useEffect(() => {
    if (closures && closures.paginationData.pageCount != pageCount) {
      setPageCount(
        closures.paginationData.pageCount > 0
          ? closures.paginationData.pageCount
          : 1,
      );
      setPage(1);
    }
  }, [closures]);

  const handleCloseCashRegister = async () => {
    await closeCashRegister({ businessPremiseId: businessPremiseId! });

    toast.success(t`Blagajna je bila uspešno zaključena`);

    if (permissions?.view_cash_register_closures) {
      await refetchClosures();
    }
  };

  const handleDeleteClosure = async (closureId: number) => {
    await deleteClosure(closureId);

    toast.success(t`Zaključek je bil uspešno izbrisan`);

    if (permissions?.view_cash_register_closures) {
      await refetchClosures();
    }
  };

  const columns = [
    // {
    //   key: "id",
    //   label: t`Št.`,
    // },
    {
      key: "opened",
      label: t`Odprtje`,
    },
    {
      key: "closed",
      label: t`Zaprtje`,
    },
    ...(!permissions?.cash_register_restrict_to_self
      ? [
          {
            key: "finalAmountFormatted",
            label: t`Znesek`,
          },
        ]
      : []),
    {
      key: "actions",
      label: t`Akcije`,
    },
  ];

  const rows: GetCashRegisterClosures["response"]["closures"] =
    isClosuresFetching
      ? Array(perPage)
          .fill(null)
          .map((_, index) => {
            return {
              id: index,
              opened: "XXXX-XXXXX",
              closed: "XX,XX X",
              finalAmountFormatted: "XX,XX",
            };
          })
      : closures?.closures.map((closure) => {
          return {
            id: closure.id,
            opened: closure.opened,
            closed: closure.closed,
            finalAmountFormatted: closure.finalAmountFormatted,
          };
        }) || [];

  const renderCell = useCallback(
    (
      item: GetCashRegisterClosures["response"]["closures"][number],
      columnKey: React.Key,
    ) => {
      const cellValue = item[columnKey as keyof typeof item];

      switch (columnKey) {
        case "actions":
          return (
            <div className="relative flex items-center gap-2">
              <Tooltip
                color="danger"
                content={t`Izbriši zaključek`}
                classNames={{
                  base: "pointer-events-none",
                }}
                closeDelay={50}
              >
                <AlertButton
                  buttonProps={{
                    isIconOnly: true,
                    variant: "light",
                  }}
                  title={t`Izbriši zaključek`}
                  content={t`Ali ste prepričani, da želite izbrisati zaključek?`}
                  confirmProps={{
                    onClick: () => handleDeleteClosure(item.id),
                    isLoading: isDeletingClosure,
                  }}
                >
                  <span className="cursor-pointer text-sm text-danger active:opacity-50">
                    <Trash2 size={20} />
                  </span>
                </AlertButton>
              </Tooltip>
            </div>
          );
        default:
          return cellValue;
      }
    },
    [isDeletingClosure],
  );

  if (!posAuthData?.userId) {
    return <div></div>;
  }

  return (
    <>
      <div>
        {permissions?.allow_closing_cash_register ? (
          <>
            <Divider />

            <Button
              fullWidth
              color="primary"
              onPress={handleCloseCashRegister}
              isLoading={isClosingCashRegister}
              className="mt-4"
            >
              <Trans>Zaključi blagajno</Trans>
            </Button>
          </>
        ) : undefined}

        <LimeAlert
          color="danger"
          message={closeCashRegisterErrorMessage}
          className="my-2"
        />
        <LimeAlert
          color="danger"
          message={closuresErrorMessage}
          className="my-2"
        />
        <LimeAlert
          color="danger"
          message={deleteClosureErrorMessage}
          className="my-2"
        />

        {permissions?.view_cash_register_closures ? (
          <>
            <Divider className="my-4" />
            <p className="mb-2 font-medium">
              <Trans>Zaključki blagajne</Trans>
            </p>

            {isClosuresFetching ? (
              <Spinner className="my-4" />
            ) : (
              <Table
                selectionMode="single"
                fullWidth
                shadow="none"
                onRowAction={(key) => {
                  setSelectedClosureId(Number(key));
                }}
                isStriped
                classNames={{
                  wrapper: "p-0 pb-4",
                }}
                aria-label={t`Zaključki blagajne`}
                bottomContent={
                  <div className="flex w-full justify-center">
                    <Pagination
                      isCompact
                      showControls
                      showShadow
                      color="primary"
                      page={page}
                      total={pageCount}
                      onChange={(page) => setPage(page)}
                    />
                  </div>
                }
              >
                <TableHeader columns={columns}>
                  {(column) => (
                    <TableColumn key={column.key}>{column.label}</TableColumn>
                  )}
                </TableHeader>
                <TableBody
                  items={rows}
                  className="p-0"
                  emptyContent={
                    isClosuresFetching
                      ? undefined
                      : t`Nimate zaključkov blagajne`
                  }
                >
                  {(item) => (
                    <TableRow key={item.id}>
                      {(columnKey) => (
                        <TableCell>
                          <Skeleton
                            isLoaded={!isClosuresFetching}
                            className="rounded-xl"
                          >
                            {renderCell(item, columnKey)}
                          </Skeleton>
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}

            <LimeAlert
              color="danger"
              message={closuresErrorMessage}
              className="my-2"
            />
          </>
        ) : undefined}
      </div>

      <ClosingDetailsModal
        businessPremiseId={businessPremiseId!}
        closureId={selectedClosureId}
        handleClose={() => setSelectedClosureId(undefined)}
      />
    </>
  );
};
