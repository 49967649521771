import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { api } from "@/lib/api-client";
import { useSidebarStore } from "@/stores/useSidebarStore";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { useLingui } from "@lingui/react";
import { Text } from "@mantine/core";
import { usePostHog } from "posthog-js/react";
import { useEffect, type JSX } from "react";
import { BsCurrencyEuro } from "react-icons/bs";
import {
  RiAdminFill,
  RiBarChartFill,
  RiBuilding4Line,
  RiCalendarEventFill,
  RiGroupFill,
  RiHistoryFill,
  RiMapPin2Fill,
  RiMenu3Fill,
  RiNotification3Fill,
  RiServiceFill,
  RiSettings3Fill,
  RiUserAddFill,
  RiGiftFill,
} from "react-icons/ri";
import {
  FileCog,
  List,
  ReceiptText,
  Blocks,
  Wallet,
  Package,
  Truck,
  PackageOpen,
  Scroll,
  LogOut,
  Drama,
  User,
  Mail,
  Phone,
} from "lucide-react";
import useAccountInfo from "../../hooks/useAccountInfo";
import useFeature from "../../hooks/useFeature";
import { useScheduleStore } from "../../pages/Dashboard/Schedule/Stores/useScheduleStore";
import useUserPermissionsStore from "../../stores/useUserPermissionsStore";
import { useMediaQuery } from "@mantine/hooks";
import { useLocation } from "react-router";
import { cn } from "@/utils";
import {
  Button,
  Link,
  LinkProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@heroui/react";

type SidebarPageListItem = {
  name: string;
  icon: JSX.Element;
  link: string;
  show: boolean;
  checkLinkStartsWith?: boolean;
  disabled?: boolean;
};

const Sidebar = () => {
  const posthog = usePostHog();
  const location = useLocation();
  const page = location.pathname;

  const { isOpen: sidebarOpen, toggle: setSidebarOpen } = useSidebarStore(
    (state) => state,
  );
  useLingui();

  const { selectSchedule } = useScheduleStore((state) => state);

  const { isFeatureEnabled } = useFeature();

  const accountInfo = useAccountInfo((state) => state.accountInfo);

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const { mutateAsync: loginAsAdmin } = api.admin.useLoginAsAdmin();

  const { mutateAsync: deleteCookie } = api.cookie.useDeleteHttpCookie();

  const mainPagesList: SidebarPageListItem[] = [
    {
      name: t`Koledar`,
      icon: <RiCalendarEventFill className="shrink-0" size={18} />,
      link: "/dashboard/calendar",
      show: true,
    },
    {
      name: t`Pregled strank`,
      icon: <RiGroupFill className="shrink-0" size={18} />,
      link: "/dashboard/completed",
      show: permissions.view_customer_list,
    },
    {
      name: t`Analitika`,
      icon: <RiBarChartFill className="shrink-0" size={18} />,
      link: "/dashboard/analytics",
      show: permissions.view_analytics,
    },
    {
      name: t`Admin`,
      icon: <RiAdminFill className="shrink-0" size={18} />,
      link: "/dashboard/admin",
      show: ["admin", "salesman"].includes(accountInfo.systemRole),
    },
  ];

  const taxRegisterPagesList: SidebarPageListItem[] = isFeatureEnabled(
    "TAX_REGISTER",
  )
    ? [
        {
          name: t`Nov račun`,
          icon: <ReceiptText className="shrink-0" size={18} />,
          link: "/dashboard/tax-register/invoice/new",
          show: permissions.view_pos_page,
        },
        {
          name: t`Arhiv računov`,
          icon: <List className="shrink-0" size={18} />,
          link: "/dashboard/tax-register/invoice",
          show: permissions.view_invoice_archive_page,
        },
        {
          name: t`Blagajna`,
          icon: <Wallet className="shrink-0" size={18} />,
          link: "/dashboard/tax-register/cash-register",
          show:
            permissions.view_cash_register_closures ||
            permissions.view_cash_register_status_summary_view,
          checkLinkStartsWith: true,
        },
        {
          name: t`Zaloga`,
          icon: <PackageOpen className="shrink-0" size={18} />,
          link: "/dashboard/product-stock",
          show:
            isFeatureEnabled("PRODUCTS") && permissions.view_product_stock_page,
          checkLinkStartsWith: true,
        },
        {
          name: t`Inventura`,
          icon: <Scroll className="shrink-0" size={18} />,
          link: "/dashboard/stock-taking",
          show:
            isFeatureEnabled("PRODUCTS") && permissions.view_stock_taking_page,
          checkLinkStartsWith: true,
        },
        {
          name: t`Dobavnice`,
          icon: <Truck className="shrink-0" size={18} />,
          link: "/dashboard/delivery-orders",
          show:
            isFeatureEnabled("PRODUCTS") &&
            permissions.view_delivery_orders_page,
          checkLinkStartsWith: true,
        },
        {
          name: t`Nastavitve`,
          icon: <FileCog className="shrink-0" size={18} />,
          link: "/dashboard/tax-register/settings/setup",
          show: permissions.view_tax_register_settings_page,
          checkLinkStartsWith: true,
        },
      ]
    : [];

  const settingsPagesList: SidebarPageListItem[] = [
    {
      name: t`Lokacija`,
      icon: <RiMapPin2Fill className="shrink-0" size={18} />,
      link: "/dashboard/locations",
      show: permissions.view_locations_page,
    },
    {
      name: t`Storitve`,
      icon: <RiServiceFill className="shrink-0" size={18} />,
      link: "/dashboard/services",
      show: permissions.view_services_page,
    },
    {
      name: t`Produkti`,
      icon: <Package className="shrink-0" size={18} />,
      link: "/dashboard/products",
      show: isFeatureEnabled("PRODUCTS") && permissions.view_products_page,
    },
    {
      name: t`Sredstva`,
      icon: <RiBuilding4Line className="shrink-0" size={18} />,
      link: "/dashboard/resource-groups",
      show: isFeatureEnabled("RESOURCES") && permissions.view_resources_page,
    },
    {
      name: t`Osebje`,
      icon: <RiUserAddFill className="shrink-0" size={18} />,
      link: "/dashboard/users",
      show: permissions.view_users_page,
    },

    {
      name: t`Urniki`,
      icon: <RiCalendarEventFill className="shrink-0" size={18} />,
      link: "/dashboard/schedule",
      show: permissions.view_availability,
    },
    {
      name: t`Splošne nastavitve`,
      icon: <RiSettings3Fill className="shrink-0" size={18} />,
      link: "/dashboard/settings",
      show: permissions.view_settings_page,
    },
    {
      name: t`Obvestila`,
      icon: <RiNotification3Fill className="shrink-0" size={18} />,
      link: "/dashboard/notifications",
      show: permissions.view_notifications_page && accountInfo?.onboardingDone,
    },
    {
      name: t`Zgodovina sprememb`,
      icon: <RiHistoryFill className="shrink-0" size={18} />,
      link: "/dashboard/change-log",
      show: isFeatureEnabled("CHANGELOG") && permissions.view_change_log_page,
    },
    {
      name: t`Spletna plačila`,
      icon: <BsCurrencyEuro className="shrink-0" size={18} />,
      link: "/dashboard/stripe",
      show:
        isFeatureEnabled("PAYMENTS") && permissions.view_online_payments_page,
    },
    {
      name: t`Darilni boni`,
      icon: <RiGiftFill className="shrink-0" size={18} />,
      link: "/dashboard/gift-card",
      show: isFeatureEnabled("GIFTCARD") && permissions.view_gift_cards_page,
    },
    {
      name: t`Integracije`,
      icon: <Blocks className="shrink-0" size={18} />,
      link: "/dashboard/integrations",
      show:
        isFeatureEnabled("INTEGRATION_FACEBOOK_CONVERSION_API") && // TODO: better check if ANY feature is enabled
        permissions.view_integrations_page,
    },
  ];

  const mainPages = mainPagesList.map((item) => {
    if (!item.show) return;
    return (
      <SidebarItem
        key={item.link}
        isOpen={sidebarOpen}
        isSelected={page === item.link}
        onClick={() => onSelect(item.link)}
        href={item.link}
        icon={item.icon}
        text={item.name}
      />
    );
  });

  const taxRegisterPages = taxRegisterPagesList.map((item) => {
    if (!item.show) return;

    const isSelected = item.checkLinkStartsWith
      ? page.startsWith(item.link)
      : page === item.link;

    return (
      <SidebarItem
        key={item.link}
        isOpen={sidebarOpen}
        isDisabled={item.disabled}
        isSelected={isSelected}
        onClick={item.disabled ? undefined : () => onSelect(item.link)}
        href={item.disabled ? undefined : item.link}
        icon={item.icon}
        text={item.name}
      />
    );
  });

  const settingsPages = settingsPagesList.map((item) => {
    if (!item.show) return;

    const isSelected = page.includes(item.link);

    return (
      <SidebarItem
        key={item.link}
        isOpen={sidebarOpen}
        isSelected={isSelected}
        onPress={() => onSelect(item.link)}
        href={item.link}
        icon={item.icon}
        text={item.name}
      />
    );
  });

  const isMobile = useMediaQuery("(max-width: 768px)") || false;

  useEffect(() => {
    if (isMobile) {
      setSidebarOpen(false);
    }
  }, [isMobile]);

  function onSelect(link: string) {
    if (link === "/dashboard/schedule") {
      selectSchedule(null);
    }

    if (isMobile) {
      setSidebarOpen(false);
    }
  }

  const logout = () => {
    posthog?.reset();
    localStorage.removeItem("usr_token");
    deleteCookie({ cookieName: "pos-token" });

    // @ts-expect-error - correct
    window?.productFruits?.services?.destroy();
    window.location.reload();
  };

  const handleLoginAsAdmin = async () => {
    try {
      const response = await loginAsAdmin(accountInfo.superAdmin.clientId);

      localStorage.setItem("usr_token", response.token);
      deleteCookie({ cookieName: "pos-token" });

      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div
        className={cn(
          "ease-[cubic-bezier(0.19,1,0.22,1)] duration-300 transition-width",
          "fixed z-50 flex h-screen max-h-screen flex-col overflow-scroll overflow-x-hidden bg-[#eff4f2] py-7 pb-[100px] text-left [&::-webkit-scrollbar]:[width:0px]",
          "md:relative md:z-auto md:h-auto md:bg-transparent md:px-0 md:pb-0",
          {
            "w-0 md:w-[60px]": !sidebarOpen,
            "w-full md:w-[270px]": sidebarOpen,
          },
        )}
      >
        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className={cn(
            "ease-[cubic-bezier(0.19,1,0.22,1)] transition-transform duration-300",
            "mb-8 flex -translate-x-[122px] cursor-pointer items-center justify-between border-none bg-none px-5",
            {
              "translate-x-0": sidebarOpen,
            },
          )}
        >
          <img
            className={cn(
              "ease-[cubic-bezier(0.19,1,0.22,1)] transition-all duration-200",
              {
                "opacity-100": sidebarOpen,
                "opacity-0": !sidebarOpen,
              },
            )}
            src="/logo_full_green.svg"
            alt="Lime"
            style={{ userSelect: "none", maxWidth: "120px" }}
          />
          <div>
            <RiMenu3Fill style={{ fontSize: "25px", color: "#A0C5B5" }} />
          </div>
        </button>
        <ItemsWrapper>{mainPages}</ItemsWrapper>

        {isFeatureEnabled("TAX_REGISTER") && (
          <>
            <PagesGroupLabel isOpen={sidebarOpen}>
              <Trans>Davčna blagajna</Trans>
            </PagesGroupLabel>
            <ItemsWrapper>{taxRegisterPages}</ItemsWrapper>
          </>
        )}

        <PagesGroupLabel isOpen={sidebarOpen}>
          <Trans>Nastavitve</Trans>
        </PagesGroupLabel>
        <ItemsWrapper>{settingsPages}</ItemsWrapper>
        <div style={{ flex: 1, padding: "10px 0" }}></div>

        <UserPopover sidebarOpen={sidebarOpen} accountInfo={accountInfo} />

        {accountInfo.superAdmin ? (
          <LogoutButton
            isOpen={sidebarOpen}
            onClick={handleLoginAsAdmin}
            isIncognito
          />
        ) : (
          <LogoutButton isOpen={sidebarOpen} onClick={() => logout()} />
        )}
        <Text
          variant={TextVariant.Caption}
          ta={"center"}
          c={Color.SecondaryText}
        >
          {import.meta.env.VITE_APP_VERSION}
        </Text>
      </div>
    </>
  );
};

const ItemsWrapper = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex flex-col gap-1">{children}</div>;
};

const PagesGroupLabel = ({
  children,
  isOpen,
}: {
  children: React.ReactNode;
  isOpen: boolean;
}) => {
  return (
    <p
      className={cn(
        "ease-[cubic-bezier(0.19,1,0.22,1)] mt-6 h-7 whitespace-nowrap pl-5 text-xs font-medium text-[#969ba9] opacity-0 transition-all duration-200",
        {
          "opacity-100": isOpen,
        },
      )}
    >
      {children}
    </p>
  );
};

const LogoutButton = ({
  isOpen,
  onClick,
  isIncognito,
}: {
  isOpen: boolean;
  onClick: () => void;
  isIncognito?: boolean;
}) => {
  return (
    <Button
      startContent={
        isIncognito ? (
          <Drama size={16} className="shrink-0" />
        ) : (
          <LogOut size={16} className="shrink-0" />
        )
      }
      className={cn(
        "ml-1 shrink-0 justify-start rounded-r-none text-[#6c7b86]",
        {
          "bg-[#df3400] text-white data-[hover=true]:bg-[#df3400]/90":
            isIncognito,
        },
      )}
      variant="light"
      onPress={onClick}
    >
      <div
        className={cn(
          "ease-[cubic-bezier(0.19,1,0.22,1)] translate-x-1 pl-1 text-sm font-medium text-[#6c7b86] opacity-0 transition-all duration-200",
          {
            "translate-x-0 opacity-100": isOpen,
            "text-white": isIncognito,
          },
        )}
      >
        {isIncognito ? (
          <Trans>Nazaj v admin račun</Trans>
        ) : (
          <Trans>Odjava</Trans>
        )}
      </div>
    </Button>
  );
};

const UserPopover = ({
  sidebarOpen,
  accountInfo,
}: {
  sidebarOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accountInfo: any;
}) => {
  return (
    <Popover placement="right-end" showArrow offset={sidebarOpen ? -40 : -20}>
      <PopoverTrigger>
        <Button
          startContent={<User size={16} className="shrink-0" />}
          className="ml-1 shrink-0 justify-start rounded-r-none text-[#6c7b86]"
          variant="light"
        >
          <div
            className={cn(
              "translate-x-1 pl-1 text-sm font-medium text-[#6c7b86] opacity-0 transition-all duration-200 ease-out",
              {
                "translate-x-0 opacity-100": sidebarOpen,
              },
            )}
          >
            <Trans>Uporabnik</Trans>
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <div className="px-1 py-2">
          <p className="mb-2 text-sm font-medium">{accountInfo.businessName}</p>

          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
              <User size={16} className="shrink-0" />
              <p className="text-sm">
                {accountInfo.userName} {accountInfo.userLastName}
              </p>
            </div>
            {accountInfo.userEmail && (
              <div className="flex items-center gap-2">
                <Mail size={16} className="shrink-0" />
                <p className="text-sm">{accountInfo.userEmail}</p>
              </div>
            )}
            {accountInfo.userPhone && (
              <div className="flex items-center gap-2">
                <Phone size={16} className="shrink-0" />
                <p className="text-sm">{accountInfo.userPhone}</p>
              </div>
            )}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

const SidebarItem = ({
  isSelected,
  isOpen,
  isDisabled,
  text,
  icon,
  ...props
}: LinkProps & {
  isSelected?: boolean;
  isOpen?: boolean;
  isDisabled?: boolean;
  text: string;
  icon: React.ReactNode;
}) => {
  return (
    <Link
      {...props}
      className={cn(
        "ease-[cubic-bezier(0.19, 1, 0.22, 1)] transition-all duration-500",
        "flex h-9 min-w-36 cursor-pointer items-center justify-start",
        "hover:text-white",
        {
          "pointer-events-none": isDisabled,
        },
      )}
    >
      {/** left knob */}
      <div
        className={cn(
          "ease-[cubic-bezier(0.19, 1, 0.22, 1)] transition-all duration-200",
          "h-full w-0 rounded-r-sm bg-primary",
          {
            "w-1": isSelected,
          },
        )}
      ></div>

      {/** content */}
      <div
        className={cn(
          "ease-[cubic-bezier(0.4, 0, 0.2, 1)] duration-150 transition-background",
          "ml-[10px] flex h-full w-full items-center whitespace-pre rounded-l-md bg-transparent pl-[10px] font-medium text-[var(--secondary-text)]",
          {
            "overflow-hidden bg-primary text-white": isSelected,
            "hover:bg-[#dfece6]": !isSelected,
          },
        )}
      >
        <div className="flex h-[20px] w-[20px] items-center">{icon}</div>
        <p
          className={cn(
            "ease-[cubic-bezier(0.19, 1, 0.22, 1)] transition-all duration-200",
            "z-10 translate-x-1 pl-[10px] text-sm opacity-0",
            {
              "translate-x-0 opacity-100": isOpen,
            },
          )}
        >
          {text}
        </p>
      </div>
    </Link>
  );
};

export default Sidebar;
