import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { LimeTextInput } from "@/Components/LimeTextInput";
import { api } from "@/lib/api-client";
import { ActionButton } from "@/pages/Dashboard/ResourceGroups/Components/ActionButton";
import usePreferredLanguageStore, {
  SUPPORTED_LANGUAGES,
} from "@/stores/usePreferredLanguageStore";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { i18n } from "@lingui/core";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  Flex,
  Radio,
  RadioGroup,
  Text,
} from "@mantine/core";
import { UseFormReturnType, useForm } from "@mantine/form";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { MdTranslate } from "react-icons/md";
import { useNavigate, useParams } from "react-router";

type FormType = {
  channel: "sms" | "email";
  MessageTemplateLocalized: {
    language: (typeof SUPPORTED_LANGUAGES)[number];
    message: string;
    subject?: string;
  }[];
};

export const UpsertTemplate = () => {
  const id = useParams().id;

  const { mutateAsync: createTemplate } = api.message.usePostMessageTemplate();
  const { mutateAsync: updateTemplate } =
    api.message.usePutMessageByTemplateId();

  const navigate = useNavigate();

  const form = useForm<FormType>({
    initialValues: {
      channel: "sms",
      MessageTemplateLocalized: SUPPORTED_LANGUAGES.map((language) => ({
        language,
        message: "",
      })),
    },
    validate: {
      MessageTemplateLocalized: (data) => {
        if (data.every((ld) => !ld.message.length)) return t`Vnesite sporočilo`;
      },
    },
  });

  useEffect(() => {
    if (!id) return;
    api.message
      .getMessageByTemplateId(Number(id))
      .then((data) => {
        const currentFormData = form.values;

        const fetchedTemplate = data.template;
        currentFormData.channel = fetchedTemplate.channel;
        currentFormData.MessageTemplateLocalized.forEach(
          (localizedMessage, index) => {
            localizedMessage.message =
              fetchedTemplate.MessageTemplateLocalized.find(
                (l) => l.language === localizedMessage.language,
              )?.message || "";

            if (fetchedTemplate.channel === "email") {
              localizedMessage.subject =
                fetchedTemplate.MessageTemplateLocalized.find(
                  (l) => l.language === localizedMessage.language,
                )?.subject || "";
            }
          },
        );

        form.setValues(currentFormData);
      })
      .catch(() => navigate("/dashboard/notifications/templates/create"));
  }, [id, navigate]);

  const isMobile = useMediaQuery("(max-width: 768px)");

  /**
   *  drawer state
   */
  const [
    messageLocalizedOpened,
    { open: openMessageLocalizedDrawer, close: closeMessageLocalized },
  ] = useDisclosure(false);
  const [
    subjectLocalizedOpened,
    { open: openSubjectLocalizedDrawer, close: closeSubjectLocalized },
  ] = useDisclosure(false);

  /**
   * default language
   */
  const { preferredLanguage } = usePreferredLanguageStore((state) => state);
  const defaultLanguage =
    preferredLanguage.userPreferredLanguage ||
    preferredLanguage.clientPreferredLanguage ||
    "sl";
  const defaultLanguageIndex = form.values.MessageTemplateLocalized.findIndex(
    (name) => name.language === defaultLanguage,
  );

  const handleUpsertTemplate = async (values: FormType) => {
    if (!id) {
      await createTemplate(values);

      notifications.show({
        message: t`Sporočilo ustvarjeno`,
        color: "green",
      });

      navigate("/dashboard/notifications/templates");
    } else {
      await updateTemplate({
        id: Number(id),
        template: values,
      });

      notifications.show({
        message: t`Sporočilo posodobljeno`,
        color: "green",
      });

      navigate("/dashboard/notifications/templates");
    }
  };

  return (
    <Flex
      direction={"column"}
      maw={"450px"}
      mt={30}
      w={"100%"}
      px={isMobile ? 20 : 0}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <form onSubmit={form.onSubmit(handleUpsertTemplate)}>
        <Flex direction={"column"} gap={20} w={"100%"}>
          <RadioGroup
            label={t`Vrsta sporočila`}
            {...form.getInputProps("channel")}
          >
            <Flex direction={"column"} gap={5} mt={10}>
              <Radio value={"sms"} label={t`SMS`}></Radio>
              <Radio value={"email"} label={t`Email`}></Radio>
            </Flex>
          </RadioGroup>
          <Collapse in={form.values.channel === "email"}>
            <LimeTextInput
              {...form.getInputProps(
                `localized.${defaultLanguageIndex}.subject`,
              )}
              label={t`Zadeva`}
              error={form.errors.subject}
              action={{
                text: t`Nastavitve prevodov`,
                leftSection: <MdTranslate />,
                //   onClick: () => console.log("nastavitve prevodov"),
                onClick: () => openSubjectLocalizedDrawer(),
              }}
            />
          </Collapse>
          <LimeTextInput
            {...form.getInputProps(
              `MessageTemplateLocalized.${defaultLanguageIndex}.message`,
            )}
            error={form.errors.MessageTemplateLocalized}
            label={t`Sporočilo`}
            action={{
              text: t`Nastavitve prevodov`,
              leftSection: <MdTranslate />,
              //   onClick: () => console.log("nastavitve prevodov"),
              onClick: () => openMessageLocalizedDrawer(),
            }}
          />
        </Flex>

        <ActionButton
          text={t`Shrani`}
          onBackButtonClick={() =>
            navigate("/dashboard/notifications/templates")
          }
        ></ActionButton>

        {/* <Button type="submit">Shrani</Button> */}
      </form>

      <MessageLanguageSettingsDrawer
        opened={messageLocalizedOpened}
        form={form}
        close={closeMessageLocalized}
      ></MessageLanguageSettingsDrawer>
      <SubjectLanguageSettingsDrawer
        opened={subjectLocalizedOpened}
        form={form}
        close={closeSubjectLocalized}
      ></SubjectLanguageSettingsDrawer>
    </Flex>
  );
};

const MessageLanguageSettingsContent = ({
  close,
  form,
}: {
  close: () => void;
  form: UseFormReturnType<FormType>;
}) => {
  const firstLanguage = i18n.locale;

  const sortedLanguages = form.values.MessageTemplateLocalized.sort((a, b) =>
    a.language === firstLanguage ? -1 : b.language === firstLanguage ? 1 : 0,
  );

  const [localizedMessages, setLocalizedMessages] = useState(
    JSON.parse(JSON.stringify(sortedLanguages)) as typeof sortedLanguages,
  );

  return (
    <Flex direction={"column"} justify={"space-between"} h={"370px"}>
      <Flex direction={"column"} gap={"md"} mt={"sm"}>
        {localizedMessages.map((localizedMessage, index) => (
          <LimeTextInput
            key={localizedMessage.language}
            label={
              <Text>
                <Text
                  span
                  c={Color.PrimaryText}
                  variant={TextVariant.BodyEmphasized}
                >
                  <Trans>Ime storitve</Trans>
                </Text>
                <Text
                  span
                  c={Color.SecondaryText}
                  variant={TextVariant.Caption}
                >
                  ({localizedMessage.language.toUpperCase()})
                </Text>
              </Text>
            }
            value={localizedMessage.message}
            onChange={(e) => {
              const newLocalizedMessages = [...localizedMessages];
              newLocalizedMessages[index].message = e.target.value;
              setLocalizedMessages(newLocalizedMessages);
            }}
          />
        ))}
      </Flex>

      <Box pb={"lg"}>
        <Divider my={"md"} />

        <Flex justify={"center"} align={"center"} gap={"md"}>
          <Button variant="white" onClick={close}>
            <Trans>Prekliči</Trans>
          </Button>
          <Button
            onClick={() => {
              form.setValues({ MessageTemplateLocalized: localizedMessages });
              close();
            }}
          >
            <Trans>Shrani</Trans>
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};
const MessageLanguageSettingsDrawer = ({
  opened,
  close,
  form,
}: {
  opened: boolean;
  close: () => void;
  form: UseFormReturnType<FormType>;
}) => {
  return (
    <Drawer
      opened={opened}
      position="bottom"
      onClose={close}
      title={t`Nastavitve prevodov`}
      overlayProps={{ backgroundOpacity: 0.2, blur: 1 }}
      styles={{
        content: {
          borderTopLeftRadius: "var(--mantine-radius-lg)",
          borderTopRightRadius: "var(--mantine-radius-lg)",
        },
        body: {
          paddingBottom: 0,
        },
      }}
      removeScrollProps={{
        allowPinchZoom: true,
      }}
      size={"450px"}
    >
      <MessageLanguageSettingsContent form={form} close={close} />
    </Drawer>
  );
};
const SubjectLanguageSettingsContent = ({
  close,
  form,
}: {
  close: () => void;
  form: UseFormReturnType<FormType>;
}) => {
  const firstLanguage = i18n.locale;

  const sortedLanguages = form.values.MessageTemplateLocalized.sort((a, b) =>
    a.language === firstLanguage ? -1 : b.language === firstLanguage ? 1 : 0,
  );

  const [localizedMessages, setLocalizedMessages] = useState(
    JSON.parse(JSON.stringify(sortedLanguages)) as typeof sortedLanguages,
  );

  return (
    <Flex direction={"column"} justify={"space-between"} h={"370px"}>
      <Flex direction={"column"} gap={"md"} mt={"sm"}>
        {localizedMessages.map((localizedMessage, index) => (
          <LimeTextInput
            key={localizedMessage.language}
            label={
              <Text>
                <Text
                  span
                  c={Color.PrimaryText}
                  variant={TextVariant.BodyEmphasized}
                >
                  <Trans>Ime storitve</Trans>
                </Text>
                <Text
                  span
                  c={Color.SecondaryText}
                  variant={TextVariant.Caption}
                >
                  ({localizedMessage.language.toUpperCase()})
                </Text>
              </Text>
            }
            value={localizedMessage.subject}
            onChange={(e) => {
              const newLocalizedMessages = [...localizedMessages];
              newLocalizedMessages[index].subject = e.target.value;
              setLocalizedMessages(newLocalizedMessages);
            }}
          />
        ))}
      </Flex>

      <Box pb={"lg"}>
        <Divider my={"md"} />

        <Flex justify={"center"} align={"center"} gap={"md"}>
          <Button variant="white" onClick={close}>
            <Trans>Prekliči</Trans>
          </Button>
          <Button
            onClick={() => {
              form.setValues({ MessageTemplateLocalized: localizedMessages });
              close();
            }}
          >
            <Trans>Shrani</Trans>
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};
const SubjectLanguageSettingsDrawer = ({
  opened,
  close,
  form,
}: {
  opened: boolean;
  close: () => void;
  form: UseFormReturnType<FormType>;
}) => {
  return (
    <Drawer
      opened={opened}
      position="bottom"
      onClose={close}
      title={t`Nastavitve prevodov`}
      overlayProps={{ backgroundOpacity: 0.2, blur: 1 }}
      styles={{
        content: {
          borderTopLeftRadius: "var(--mantine-radius-lg)",
          borderTopRightRadius: "var(--mantine-radius-lg)",
        },
        body: {
          paddingBottom: 0,
        },
      }}
      removeScrollProps={{
        allowPinchZoom: true,
      }}
      size={"450px"}
    >
      <SubjectLanguageSettingsContent form={form} close={close} />
    </Drawer>
  );
};
