import { t } from "@lingui/core/macro";
import { api } from "@/lib/api-client";
import { useLingui } from "@lingui/react";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { fullPhoneNumberToNumberAndCountryCode } from "../../../Utilities";
import { Language } from "@/server-types";
import usePreferredLanguageStore from "@/stores/usePreferredLanguageStore";

const useModalCustomer = () => {
  useLingui();
  const { preferredLanguage } = usePreferredLanguageStore((state) => state);
  const form = useForm({
    initialValues: {
      name: "",
      lastName: "",
      email: "",
      gsm: "",
      notes: "",
      countryCode: "",
      language: preferredLanguage.clientPreferredLanguage as Language,
    },
    validate: {
      email: (value) => {
        if (value.length === 0) return null;

        return /^\S+@\S+$/.test(value) ? null : t`Neveljaven e-poštni naslov`;
      },
      name: (value) => (value.length > 0 ? null : t`Ime je obvezno polje`),
      lastName: (value) =>
        value.length > 0 ? null : t`Priimek je obvezno polje`,
      gsm: (value) => {
        if (!value || value.length === 0) return null;

        return isValidPhoneNumber(value)
          ? null
          : t`Neveljavna telefonska številka`;
      },
    },
    validateInputOnChange: true,
  });

  const [modalOpened, setModalOpened] = useState(false);

  const submitCustomerForm = async (values: typeof form.values) => {
    let parsedGsm, parsedCountryCode;
    const { name, lastName, email, gsm, notes, language } = values;
    const result = fullPhoneNumberToNumberAndCountryCode(gsm);
    if (result && "countryCode" in result && "gsm" in result) {
      parsedGsm = result.gsm;
      parsedCountryCode = result.countryCode;
    }
    const data = {
      name,
      lastName,
      email,
      gsm: parsedGsm,
      notes,
      countryCode: parsedCountryCode,
      language,
    };
    const response = await createCustomer(data);
    console.log("response", response);
  };

  const { mutateAsync: createCustomer } = api.customer.useAddCustomer();

  const toggleModal = () => {
    setModalOpened(!modalOpened);

    if (!modalOpened === false) {
      form.reset();
    }
  };

  return [form, modalOpened, toggleModal, setModalOpened, submitCustomerForm];
};

export default useModalCustomer;
