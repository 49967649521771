import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router";
import { apiResetPassword } from "../../../apiCalls";

import { LimeTextInput } from "@/Components/LimeTextInput";
import { Box, Button, Flex, Text } from "@mantine/core";
import styled from "styled-components";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordLongEnough, setPasswordLongEnough] = useState(false);
  const [passwordContainsNumber, setPasswordContainsNumber] = useState(false);
  const [
    passwordContainsUppercaseAndLowercase,
    setPasswordContainsUppercaseAndLowercase,
  ] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get("user");

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const verify = await verifyToken(token);
      } catch (e) {
        /* window.location.href = "/";*/
        console.log(e);
      }
    };

    verifyToken();
  }, [token]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      passwordsMatch &&
      passwordLongEnough &&
      passwordContainsNumber &&
      passwordContainsUppercaseAndLowercase
    ) {
      try {
        const response = await apiResetPassword({ password, token });
        setSubmitted(true);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    // check with regex if password is long enough
    if (password.length >= 8) {
      setPasswordLongEnough(true);
    } else {
      setPasswordLongEnough(false);
    }
    //check if password contains number
    if (/\d/.test(password)) {
      setPasswordContainsNumber(true);
    } else {
      setPasswordContainsNumber(false);
    }
    //check if password contains uppercase and lowercase
    if (/[a-z]/.test(password) && /[A-Z]/.test(password)) {
      setPasswordContainsUppercaseAndLowercase(true);
    } else {
      setPasswordContainsUppercaseAndLowercase(false);
    }
    //check if passwords match
    if (password === confirmPassword && password !== "") {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  }, [password, confirmPassword]);

  return (
    <Box maw={"650px"} m={"0 auto"} ta={"left"} w={"100%"} px={"md"}>
      <div style={{ position: "absolute", left: "2rem", top: "2rem" }}>
        <img
          style={{ height: "50px" }}
          src="/public/logo_full_green.svg"
          alt=""
        />
      </div>
      <div
        style={{ height: "80px", width: "200px", left: "2rem", top: "2rem" }}
      >
        &nbsp;
      </div>
      {!submitted ? (
        <>
          <Flex direction={"column"} gap={"md"} mt={"3rem"}>
            <h1>
              <Trans>Ponastavi svoje geslo</Trans>
            </h1>

            <form style={{ width: "100%", display: "contents" }}>
              <LimeTextInput
                label={t`Novo geslo`}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <LimeTextInput
                label={t`Ponovi novo geslo`}
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <PasswordRulesContainer>
                <PasswordRule
                  style={{
                    color: passwordLongEnough ? "green" : "red",
                  }}
                >
                  - <Trans>Geslo mora vsebovati vsaj 8 znakov.</Trans>
                </PasswordRule>
                <PasswordRule
                  style={{
                    color: passwordContainsNumber ? "green" : "red",
                  }}
                >
                  - <Trans>Geslo mora vsebovati vsaj eno številko.</Trans>
                </PasswordRule>
                <PasswordRule
                  style={{
                    color: passwordContainsUppercaseAndLowercase
                      ? "green"
                      : "red",
                  }}
                >
                  -{" "}
                  <Trans>
                    Geslo mora vsebovati vsaj eno veliko in eno malo črko.
                  </Trans>
                </PasswordRule>
                <PasswordRule
                  style={{
                    color: passwordsMatch ? "green" : "red",
                  }}
                >
                  - <Trans>Gesli se ujemata.</Trans>
                </PasswordRule>
              </PasswordRulesContainer>

              <Button
                onClick={onSubmit}
                disabled={
                  !(
                    passwordLongEnough &&
                    passwordContainsNumber &&
                    passwordContainsUppercaseAndLowercase &&
                    passwordsMatch
                  )
                }
              >
                <Trans>Nadaljuj</Trans>
              </Button>
            </form>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
          </Flex>
        </>
      ) : (
        <>
          <Flex
            direction={"column"}
            style={{
              padding: "2rem",
            }}
            mt={"3rem"}
          >
            <Text mb={"md"}>
              <Trans>Uspešno ste ponastavili svoje geslo.</Trans>
            </Text>
            <Button component="a" href="/login">
              <Trans>Nadaljujte na prijavo.</Trans>
            </Button>
          </Flex>
        </>
      )}
    </Box>
  );
};

const PasswordRulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  margin-left: 1rem;
`;

const PasswordRule = styled.div`
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`;

export default ResetPassword;
