import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { CheckboxListItem } from "@/Components/CheckboxListItem/CheckboxListItem";
import { LimeTextInput } from "@/Components/LimeTextInput";
import { api } from "@/lib/api-client";
import { useGetCategories } from "@/lib/api-client/paths/category";
import { useGetServices } from "@/lib/api-client/paths/service";
import { PostResourceGroupServices } from "@/server-types";
import usePreferredLanguageStore from "@/stores/usePreferredLanguageStore";
import { TextVariant } from "@/types/text-variants";
import {
  Accordion,
  Button,
  Checkbox,
  Divider,
  Flex,
  Loader,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedState } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useEffect } from "react";
import { RiSearch2Line } from "react-icons/ri";
import { useNavigate, useParams, useSearchParams } from "react-router";
import { ActionButton } from "../Components/ActionButton";
import useManageResourceGroup from "../useManageResourceGroup";

const defaultData: PostResourceGroupServices["body"] = {
  serviceIds: [],
};

export const ServicesTab = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  const selectedResourceGroupId = id ? parseInt(id) : undefined;
  const isEditing =
    selectedResourceGroupId != -1 && searchParams.get("onboarding") == null;

  const { navigateToTab } = useManageResourceGroup();

  const { preferredLanguage } = usePreferredLanguageStore((state) => state);
  const defaultLanguage =
    preferredLanguage.userPreferredLanguage ||
    preferredLanguage.clientPreferredLanguage ||
    "sl";

  const [serviceSearch, setServiceSearch] = useDebouncedState("", 200);

  const {
    mutate: postResourceGroupServices,
    isPending: isPostingResourceGroupServices,
  } = api.resourceGroups.usePostResourceGroupServices();

  const {
    data: resourceGroupServices,
    isLoading: isGettingResourceGroupServices,
    isError: isGettingResourceGroupServicesError,
  } = api.resourceGroups.useGetResourceGroupServices(selectedResourceGroupId);

  const { data: serviceData, isLoading: isGettingServices } = useGetServices({
    search: serviceSearch,
    pagination: {
      page: 1,
      perPage: 1000,
    },
  });
  const services = serviceData?.services || [];

  const { data: categories, isLoading: isGettingCategories } = useGetCategories(
    {},
  );

  const form = useForm({
    initialValues: defaultData,
  });

  useEffect(() => {
    if (isGettingResourceGroupServicesError) {
      navigate("/dashboard/resources");
      return;
    }

    if (resourceGroupServices) {
      const data = {
        serviceIds: resourceGroupServices.map((service) => service.serviceId),
      };
      form.initialize(data);
    }
  }, [resourceGroupServices, isGettingResourceGroupServicesError]);

  const handleSelectAllClick = () => {
    form.setFieldValue(
      "serviceIds",
      form.values.serviceIds.length === services.length
        ? []
        : services.map((service) => service.serviceId),
    );
  };

  const handleServiceClick = (serviceId: number) => {
    form.setFieldValue(
      "serviceIds",
      form.values.serviceIds.includes(serviceId)
        ? form.values.serviceIds.filter((id) => id !== serviceId)
        : [...form.values.serviceIds, serviceId],
    );
  };

  const handleCategorySelectAllClick = (
    categoryId: number,
    selectAll: boolean,
  ) => {
    const servicesWithCategory = services.filter(
      (service) => service.service_tag.at(0)?.tag.tagId === categoryId,
    );

    const servicesToSelect =
      categoryId === -1 ? servicesWithoutCategories : servicesWithCategory;

    form.setFieldValue(
      "serviceIds",
      selectAll
        ? [
            ...form.values.serviceIds,
            ...servicesToSelect
              .filter(
                (service) =>
                  !form.values.serviceIds.includes(service.serviceId),
              )
              .map((service) => service.serviceId),
          ]
        : form.values.serviceIds.filter(
            (serviceId) =>
              !servicesToSelect
                .map((service) => service.serviceId)
                .includes(serviceId),
          ),
    );
  };

  const handleSubmit = (values: PostResourceGroupServices["body"]) => {
    if (!selectedResourceGroupId) return;

    try {
      postResourceGroupServices({
        resourceGroupId: selectedResourceGroupId,
        serviceIds: values,
      });

      if (!isEditing) {
        navigateToTab({
          currentTab: "services",
          direction: "next",
          resourceGroupId: selectedResourceGroupId,
        });
      } else {
        notifications.show({
          message: t`Sredstvo uspešno posodobljeno`,
          color: "green",
        });
      }
    } catch (e) {
      notifications.show({
        message: t`Napaka pri posodabljanju sredstva.`,
        color: "red",
      });
    }
  };

  const servicesWithoutCategories = services.filter(
    (service) => !service.service_tag.at(0)?.tag.tagId,
  );

  const allServicesWithoutCategoriesSelected = servicesWithoutCategories.every(
    (service) => form.values.serviceIds.includes(service.serviceId),
  );

  return (
    <form
      style={{ display: "contents" }}
      onSubmit={form.onSubmit(handleSubmit)}
    >
      <Flex
        direction={"column"}
        gap={"md"}
        align={"flex-end"}
        mt={"md"}
        pb={"81px"}
      >
        <Flex gap={"md"} w={"100%"} px={"md"}>
          <LimeTextInput
            w={"100%"}
            placeholder={t`Išči storitve`}
            leftSection={<RiSearch2Line />}
            defaultValue={serviceSearch}
            onChange={(e) => setServiceSearch(e.currentTarget.value)}
            maw={"220px"}
          />
          <Button
            variant="light"
            miw={"fit-content"}
            radius={20}
            onClick={handleSelectAllClick}
          >
            <Text variant={TextVariant.CaptionEmphasized}>
              <Trans>Izberi vse</Trans>
            </Text>
          </Button>
        </Flex>

        <Divider w={"100%"} />

        <Accordion
          w={"100%"}
          px={"md"}
          styles={{
            control: {
              borderBottom: "1px solid #ECEDEF",
            },
            item: { border: "none" },
            content: {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          {(isGettingCategories || isGettingServices) && (
            <Flex justify={"center"}>
              <Loader />
            </Flex>
          )}
          {!isGettingCategories &&
            !isGettingServices &&
            categories?.map((category) => {
              const categoryId = category.tagId;
              const categoryName =
                category.TagNameLocalized.find(
                  (tl) => tl.language === defaultLanguage,
                )?.name ||
                category.TagNameLocalized.find(
                  (tl) => tl.language === defaultLanguage,
                )?.name ||
                category.tagName ||
                "";

              const categoryServices = services.filter(
                (service) =>
                  service.service_tag.at(0)?.tag.tagId === categoryId,
              );

              if (categoryServices.length === 0) {
                return null;
              }

              const categorySelectedServices = form.values.serviceIds.filter(
                (serviceId) =>
                  categoryServices
                    .map((service) => service.serviceId)
                    .includes(serviceId),
              );

              return (
                <Accordion.Item value={categoryId.toString()} key={categoryId}>
                  <Accordion.Control>
                    <Flex gap={"md"} align={"center"}>
                      <Checkbox
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onChange={(e) =>
                          handleCategorySelectAllClick(
                            categoryId,
                            e.target.checked,
                          )
                        }
                        checked={
                          categorySelectedServices.length ===
                          categoryServices.length
                        }
                      />
                      <Text variant={TextVariant.Subheading}>
                        {categoryName}
                      </Text>
                    </Flex>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Flex direction={"column"} gap={"xs"}>
                      {categoryServices.map((service) => (
                        <CheckboxListItem
                          label={service.name}
                          onClick={() => handleServiceClick(service.serviceId)}
                          isSelected={form.values.serviceIds.includes(
                            service.serviceId,
                          )}
                          key={service.serviceId}
                        />
                      ))}
                    </Flex>
                  </Accordion.Panel>
                </Accordion.Item>
              );
            })}

          {servicesWithoutCategories.length > 0 && (
            <Accordion.Item value={"uncategorized"}>
              <Accordion.Control>
                <Flex gap={"md"} align={"center"}>
                  <Checkbox
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) =>
                      handleCategorySelectAllClick(-1, e.target.checked)
                    }
                    checked={allServicesWithoutCategoriesSelected}
                  />
                  <Text variant={TextVariant.Subheading}>
                    <Trans>Brez oznake</Trans>
                  </Text>
                </Flex>
              </Accordion.Control>
              <Accordion.Panel>
                <Flex direction={"column"} gap={"xs"}>
                  {servicesWithoutCategories.map((service) => (
                    <CheckboxListItem
                      key={service.serviceId}
                      label={service.name}
                      onClick={() => handleServiceClick(service.serviceId)}
                      isSelected={form.values.serviceIds.includes(
                        service.serviceId,
                      )}
                    />
                  ))}
                </Flex>
              </Accordion.Panel>
            </Accordion.Item>
          )}
        </Accordion>
      </Flex>

      <ActionButton
        isLoading={
          isPostingResourceGroupServices || isGettingResourceGroupServices
        }
        text={isEditing ? t`Shrani` : t`Nadaljuj`}
        onBackButtonClick={() => {
          navigateToTab({
            currentTab: "services",
            direction: "previous",
            resourceGroupId: selectedResourceGroupId,
          });
        }}
      />
    </form>
  );
};
